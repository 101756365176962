/* eslint-disable */
import {Log, WebStorageStateStore, UserManager} from 'oidc-client-ts';

var mgr = new UserManager({
  userStore: new WebStorageStateStore(),  
  authority: 'https://doordash.okta.com',
  client_id: '0oa13mt3jb32haFda0x8',
  redirect_uri: window.location.origin + '/static/callback.html',
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: 'https://doordash.okta.com/login/signout', //  window.location.origin + '/index.html',
  silent_redirect_uri: window.location.origin + '/static/silent-renew.html',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  response_mode: "fragment",
})

Log.logger = console; 
Log.level = Log.NONE; // Oidc.Log.INFO; // enable logging here

mgr.events.addUserLoaded(function (user) {  
  //console.log('New User Loaded：', arguments);
  console.log('Acess_token: ', user.access_token)
});

mgr.events.addAccessTokenExpiring(function () {
  console.log('AccessToken Expiring：', arguments);
});

mgr.events.addSilentRenewError(function () {
  console.error('Silent Renew Error：', arguments);
});

mgr.events.addUserSignedOut(function () {
  //alert('Going out!');
  console.log('UserSignedOut：', arguments);  
  mgr.signoutRedirect().then(function (resp) {
    console.log('signed out', resp);
  }).catch(function (err) {
    console.log(err)
  })
});

export function initSessionHandling(router) {
  console.log('initSessionHandling');
  mgr.events.addAccessTokenExpired(function () {
    console.log('AccessToken Expired：', arguments);
    if(!window.location.pathname.startsWith("/login")) {
      router.push("/login?ref=" + encodeURIComponent(window.location.pathname));  
    }
  });
}

// Check if there is any user logged in
export async function getSignedIn() {
  const user = await mgr.getUser()
  return user && !user.expired
}

// Get the token id
export function getIdToken() {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn();
        return resolve(null)
      } else{          
        return resolve(user.id_token)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Get the user who is logged in
export function getUser() {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn();
        return resolve(null)
      } else{          
        return resolve(user)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Renew the token manually
export function renewToken () {
  return new Promise((resolve, reject) => {
    mgr.signinSilent().then(function (user) {
      if (user == null) {
        autoSignIn(null);
      } else{
        return resolve(user)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Get the profile of the user logged in
export function getProfile () {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn()
        return resolve(null)
      } else{          
        return resolve(user.profile)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Get the session state
export function getSessionState() {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn()
        return resolve(null)
      } else{          
        return resolve(user.session_state)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Get the access token of the logged in user
export function getAcessToken() {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn()
        return resolve(null)
      } else{          
        return resolve(user.access_token)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Takes the scopes of the logged in user
export function getScopes() {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn()
        return resolve(null)
      } else{          
        return resolve(user.scopes)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

// Get the user roles logged in
export function getRole () {
  return new Promise((resolve, reject) => {
    mgr.getUser().then(function (user) {
      if (user == null) {
        autoSignIn()
        return resolve(null)
      } else{          
        return resolve(user.profile.role)
      }
    }).catch(function (err) {
      console.log(err)
      return reject(err)
    });
  })
}

function autoSignIn() {
  console.log('auto sign in');
  if(window.localStorage.getItem("ss_prevent_auto_login") != "true") {
    signIn(window.location.pathname);
  }
}
// Redirect of the current window to the authorization endpoint.
export function signIn(redirectUrl=null) {
  window.localStorage.removeItem("ss_prevent_auto_login");

  mgr.signinRedirect({state:redirectUrl}).catch(function (err) {
    console.log(err)
  })
}

// Redirect of the current window to the end session endpoint
export function signOut() {
  console.log('signing out');
  window.localStorage.setItem("ss_prevent_auto_login", true);
  mgr.signoutRedirect().then(function (resp) {
    console.log('signed out', resp);
  }).catch(function (err) {
    console.log(err)
  })
}

export function signOutSilent() {
  console.log('signing out');
  window.localStorage.setItem("ss_prevent_auto_login", true);
  mgr.signoutSilent().then(function (resp) {
    console.log('signed out', resp);
  }).catch(function (err) {
    console.log(err)
  });
}

export function sessionExpired(vueComponent) {
  console.log('session expired');
  if(vueComponent && vueComponent.$router) {
    vueComponent.$router.push("/login?ref=" + encodeURIComponent(window.location.pathname));
  } else {
    window.location = "/login?ref=" + encodeURIComponent(window.location.pathname);
  }
}
