<template>
  <main v-if="position" :class="{modal:isModal()}">
    <HeadingSubtitle v-if="!isModal()" text="Headcount Planning → Position history" icon="fa-solid fa-coins" />
    <hr v-if="!isModal()" />

    <h4 class="table-header subtitle">
      <button class="btn btn-primary" @click="$router.go(-1)" v-if="!isModal()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      Changes made to {{ position.position_id || position.id }} ({{ position.job_profile_name }}, {{ position.job_location_name }})
    </h4>
    
    <table class="data-table history-table">
      <thead>
        <tr class="data-header">
          <th>Date of change</th>
          <th>Change author</th>
          <th>Change type</th>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>

      <tbody v-if="positionHistory.length">
        <template v-for="h in positionHistory" :key="h.id">
          <tr v-for="(value, key, index) in h.changes" :key="key" :set="count=Object.keys(h.changes).length">
            <template v-if="index == 0">
              <td :rowspan="count">{{ formatDateTime(h.date_changed) }}</td>
              <td :rowspan="count" v-if="permissions.hasUsersRead"><RouterLink :to="`/user/${h.changed_by}`">{{ h.changed_by_name || h.changed_by }}</RouterLink></td>
              <td :rowspan="count" v-else>{{ h.changed_by_name || h.changed_by }}</td>
              <td :rowspan="count">{{ CHANGE_TYPE_MAPPING[h.change_type] || "" }}</td>
              <!-- <td :rowspan="count">{{ h.changes.length === 1 && Object.keys(h.changes)[0] === "approval" ? "Decision" : "Data edit" }}</td> -->
            </template>
            <td>{{ key }}</td>
            <td>{{ value.old || "-" }}</td>
            <td>{{ value.new || "-" }}</td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6"><i>No changes</i></td>
        </tr>
      </tbody>

    </table>
  </main>
</template>

<script>
  import HeadingSubtitle from "@/components/objects/text/HeadingSubtitle.vue";
  import { fetchFromApi, formatDateTime } from "@/services/AppTools";
  import { permissions } from "@/services/Permissions";

  export default {
    name: "HcPlanHistoryView",
    props: ['planId', 'positionId'],
    components: {
      HeadingSubtitle,
    },

    data() {
      return {
        CHANGE_TYPE_MAPPING: ["", "Create", "Data edit", "Approval", "Delete"],
        positionHistory: [],
        position: null,
        formatDateTime: formatDateTime,
        permissions: permissions,
      }
    },

    methods: {
      isModal() { 
        return this.planId != null; 
      }
    },

    async beforeMount() {
      let positionId = this.positionId ? this.positionId : this.$route.params.positionId;

      const response = await fetchFromApi(this, `/api/hcp/plans/${this.planId}/positions/${positionId}/history`); 
      this.position =  response.position;
      this.positionHistory = response.history;

      // reverse table (newest first)
      this.positionHistory.reverse();
    },

  }
</script>

<style scoped lang="scss">
  h4.subtitle {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 1ex;
    margin-top: 2em;
  }

  .history-table {
    margin-top: 13px;

    th {
      position: static;
    }
  }

  .data-table {
    tr {
      transition: all .2s ease-in-out;
    }

    tr.closed {
      color: #999;
    }

    th {
      position: static; // override sticky position
    }
  }
  .data-table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }

  .history-table {
    width: 100%;
    border-collapse: collapse;
    max-width: 1500px;

    tr:nth-child(even) {
      background-color: unset;
    }

    td {
      // padding: 0.5em 1em;
      border-bottom: 1px solid #eee;
      height: 2em;
    }
  }

  main.modal {
    min-height: auto;
    padding: 0;
  }
</style>