<template>
  <!-- Plan and Approvals view -->
  <div class="positions-table largeheader" v-if="accessRoles !== null">
    <div class="pos-table-data">
      <table class="data-table" :set="accesses = applyFilter(accessRoles)">
        <thead>
          <tr class="data-header">
            <th class="w200" :class="sortClass('email')" @click="setSort('email')">User</th>
            <th class="w200" :class="sortClass('role')" @click="setSort('role')">Role</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody v-if="accesses && accesses.length">
          <tr class="data-row" v-for="acs in accesses" :key="acs.id+acs.role">
            <td>{{ acs.email }}</td>
            <td>{{ acs.role }}</td>
            <td>{{ acs.pos_count ? 'Active positions: ' + acs.pos_count : '' }}</td>
          </tr>
        </tbody>
        <tr v-else>
          <td colspan="4" class="no-subplans-notice">No access data for plan.</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
  import { fetchFromApi } from '@/services/AppTools.js';

  export default {
    name: "PlanAccess",
    props: ["planId"],
    components: {
    },
    data() {
      return {
        sortcolumn: "position_id",
        sortOrder: 1,

        accessRoles: [],
      };
    },
    methods: {
      sortClass(field) {
        if (this.sortcolumn === field) {
          if (this.sortOrder === 1)
            return ["sortable", "asc"];
          return ["sortable", "desc"];
        }
        return "sortable";
      },
      setSort(field) {
        if (this.sortcolumn === field) {
          this.sortOrder *= -1;
        } else {
          this.sortcolumn = field;
          this.sortOrder = 1;
        }
      },
      applyFilter(positions) {
        let filtered = null;

          filtered = positions.filter(pos => {
            return true;
            // if (!(pos.approval === 0 && pos.position_id)) {
            //   return false;
            // }

            // if (this.filter.fulltext) {
            //   for (const part of this.filter.fulltext.toLowerCase().split(" ")) {
            //     let found = false;
            //     for (const [key, value] of Object.entries(pos)) {
            //       if(typeof value === 'string' && value.toLowerCase().includes(part)){
            //         found = true;
            //         break;
            //       }
            //     }
            //     if(!found) {
            //       for (const [key, value] of Object.entries(pos.extra_data)) {
            //         if(typeof value === 'string' && value.toLowerCase().includes(part)){
            //           found = true;
            //         }
            //       }
            //     }
            //     if(!found){
            //       return false;
            //     }
            //   }
            // }

            // return true;
          });

        // sort data
        if (!this.sortcolumn){
          return filtered;
        }

        return filtered.sort((a, b) => {
          if (a._class == "unsaved") return 1; // new items are always at the bottom
          if (a[this.sortcolumn] === null || a[this.sortcolumn] == "") return this.sortOrder;
          if (b._class == "unsaved") return 1; // new items are always at the bottom
          if (b[this.sortcolumn] === null || b[this.sortcolumn] == "") return -this.sortOrder;
          return a[this.sortcolumn] > b[this.sortcolumn] ? 1 * this.sortOrder : -1 * this.sortOrder;
        });
      },
    },
    
    async beforeMount() {
      this.accessRoles = await fetchFromApi(this, `/api/hcp/plans/${this.planId}/accessroles`);
    },
  };
</script>
  
<style lang="scss" scoped>
  .w200 {
    width: 300px;
  }

  .data-table {
    margin-top: 39px;
    th {
      position: sticky;
      top: calc(var(--header-height) + 91px);    
      background: white;
      min-width: 100px;
      line-height: 1.1em;
      padding-top: 3.5em;
      height: 2.2em;
    }

    td {
      background: none;
      min-width: 100px;
      height: 53px;
      box-sizing: border-box;
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .sortable {
    user-select: none;
    word-break: keep-all;
    cursor: pointer;
    &::after {
      content: ' ↓↑';
      visibility: hidden;
    }
  
    &:hover::after {
      content: ' ↓↑';
      visibility: visible;
    }
    &.asc::after {
      content: ' ↓';
      visibility: visible;
    }
    &.desc::after {
      content: ' ↑';
      visibility: visible;
    }
  }

</style>
