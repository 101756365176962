<template>
 <div class="subplans-table">
      <table class="data-table">
        <thead>
          <tr class="data-header">
            <th>Name</th>
            <th>Owner</th>
            <th>Sup Org</th>
            <th>Total <br>Bugdet</th>
            <th>Current <br>Bugdet</th>
            <th>Approved <br>Bugdet</th>
            <th>Pending <br>Bugdet</th>
            <th>Remaining <br>Bugdet</th>
            <th colspan="2">Status</th>
          </tr>
        </thead>
        <tbody v-if="subplans && subplans.length">
          <tr class="data-row" v-for="subplan in subplans" :key="subplan.id">
            <td><a :href="`/hcplanning/${subplan.id}`">{{ subplan.title }}</a></td>
            <td><RouterLink :to="`/user/${subplan.owner}`">
              {{ subplan.owner_first_name ? `${subplan.owner_first_name} ${subplan.owner_last_name}` : subplan.owner }}
            </RouterLink></td>
            <td>{{ subplan.sup_org_title }}</td>
            <td>{{ subplan.total_funds }}</td>
            <td>{{ subplan.current_funds }}</td>
            <td>{{ subplan.approved_funds }}</td>
            <td>{{ subplan.pending_funds }}</td>
            <td>{{ subplan.remaining_funds }}</td>
            <td>{{ subplan.status }}</td>
            <td class="actions-td">
              <button v-if="planData.current_user.is_admin" class="btn-new subtle-icon compact" title="Manage subplan settings"
                @click="this.$router.push(`/hcplanning/admin/${subplan.id}`)"><i class="fa-solid fa-gear"></i></button>
            </td>
          </tr> 
        </tbody>
        <tr v-else>
          <td colspan="4" class="no-subplans-notice">No subplans found.</td>
        </tr>
      </table>
    </div>
</template>

<script>
import { fetchFromApi } from '@/services/AppTools.js';
    export default {
        name: "SubPlans",
        props: ["planId", "planData"],
        data() {
            return {
              subplans: null,
            }
        },

        async beforeMount() {
          this.subplans = await fetchFromApi("GET", `/api/hcp/plans/${this.planId}/subplans`);
        },
    }
</script>


<style scoped lang="scss">
  .subplans-table {
    margin-top: 110px;
  }
</style>
