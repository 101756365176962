<template>
<main v-if="user">
  <h1><i class="fa-regular fa-user"></i>{{user.firstname}} {{user.lastname}} <sup title="Product+" v-if="user.extra_info && user.extra_info.function && user.extra_info.function.toLowerCase().includes('product+')"><i class="fa-solid fa-plus product-plus-user-icon"></i></sup></h1>

  <section class="highlight">
  <tabs @changed="tabChanged">
    <tab name="Summary">
      <dl class="listing">
        <dt>Id:</dt><dd>{{user.id}}</dd>
        <dt>Name:</dt><dd>{{user.firstname}} {{user.lastname}}</dd>
        <dt>Email:</dt><dd>{{user.email}}</dd>
        <dt>Team lead:</dt><dd><a :href="'/user/'+ user.teamlead_id">{{user.teamlead_name}}</a></dd>
        <template v-if="user.extra_info">
          <dt>Country:</dt><dd>{{user.extra_info.country}}</dd>
          <dt v-if="user.extra_info.support_countries">Support countries:</dt><dd v-if="user.extra_info.support_countries">{{user.extra_info.support_countries.join(", ")}}</dd>
          <dt>Function:</dt><dd>{{user.extra_info.function}}</dd>
          <dt>Job title:</dt><dd>{{user.extra_info.job_title}}</dd>
          <dt>Job type:</dt><dd>{{user.extra_info.job_type}}</dd>
          <template v-if="user.extra_info.okta_enrolled != null && !user.limited_user">
            <dt>Okta enrolled:</dt><dd>{{user.extra_info.okta_enrolled ? "YES": "NO"}}</dd>
          </template>
          <template v-if="user.extra_info.mfa_factors">
            <dt>MFA factors:</dt><dd>{{user.extra_info.mfa_factors}}</dd>
          </template>
        </template>
        <dt v-if="user.permissions">Portal permissions:</dt><dd><code style="font-size: small;">{{ formatPermissions(user.permissions) }}</code></dd>
      </dl>

      <ul class="link-list">
        <li v-if="!user.limited_user"><i class="fa-solid fa-up-right-from-square"></i> <a target="_blank" :href="user.wd_profile">Open in Workday</a></li>
        <li v-if="permissions.isIT && !user.limited_user"><i class="fa-solid fa-up-right-from-square"></i> <a target="_blank" :href="'https://admin.google.com/ac/search?query='+ user.email + '&tab=USERS'">Open in Google admin</a> <a target="_blank" style="font-size: small; margin-left: 1em; color: #999; font-style: italic;" href="https://woltwide.atlassian.net/wiki/spaces/PEOP/pages/3517776170/Open+Google+admin+link+in+IT+portal">how to open as admin</a></li>
        <li><i class="fa-solid fa-up-right-from-square"></i> <a target="_blank" :href="'https://people.wolt.com/#' + user.id">Open in People chart</a></li>
      </ul>
      <span class="small">Last updated: {{formatDateTime(user.updated)}}</span>
    </tab>
    <!-- <tab name="Licenses">
      <UserLicenses v-if="lazyLoadLic" />
    </tab>
    <tab name="Team licenses" v-if="user.is_teamlead">
      <TeamLicenses v-if="lazyLoadTeamLic" />
    </tab>     -->
    <tab name="Tickets" v-if="permissions.hasTicketsRead">
      <UserTicketLog v-if="lazyLoadTickets" />
    </tab>
    <tab name="Onboarding" v-if="permissions.hasTicketsRead">
      <UserOnboardingLog v-if="lazyLoadOnboarding" />
    </tab>
    <tab name="Okta" v-if="permissions.hasOktaStatusRead && !user.limited_user">
      <OktaStatus />
    </tab>
    <tab name="Birthrights" v-if="permissions.isIT && !user.limited_user">
      <UserBirthrights v-if="activeTab == 'Birthrights'" />
    </tab>
    <tab name="Devices" v-if="permissions.isIT">
      <UserAssets v-if="lazyLoadDevices" />
    </tab>
  </tabs>
  </section>
</main>
</template>

<script>
import UserLicenses from '@/views/users/UserLicenses.vue'
import TeamLicenses from '@/views/users/TeamLicenses.vue'
import OktaStatus from '@/views/users/OktaStatus.vue'
import UserBirthrights from '@/views/users/UserBirthrights.vue'
import UserTicketLog from '@/views/users/UserTicketLog.vue'
import UserOnboardingLog from '@/views/users/UserOnboardingLog.vue'
import UserAssets from './UserAssets.vue'
import {Tabs, Tab} from 'vue3-tabs-component';
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js'
import {permissions} from '@/services/Permissions.js'

export default {
  name: 'AppView',
  components: {
    // UserLicenses,
    // TeamLicenses,
    OktaStatus,
    UserBirthrights,
    UserTicketLog,
    UserOnboardingLog,
    UserAssets,
    Tabs, Tab
  },
  data() {
    return {
      user: null,
      lazyLoadLic: false,
      lazyLoadTeamLic: false,
      lazyLoadTickets: false,
      lazyLoadOnboarding: false,
      lazyLoadDevices: false,
      permissions: permissions,
      formatDateTime: formatDateTime,
      birthrightsRulesets: [],
      activeTab: "",
    };
  },
  methods: {
    tabChanged(selectedTab) {
      let tab = selectedTab.tab.name;

      // lazy load tab content when opened the first time
      this.lazyLoadTeamLic = this.lazyLoadTeamLic || tab == "Team licenses";
      this.lazyLoadLic = this.lazyLoadLic || tab == "Licenses";
      this.lazyLoadTickets = this.lazyLoadTickets || tab == "Tickets";
      this.lazyLoadOnboarding = this.lazyLoadOnboarding || tab == "Onboarding";
      this.lazyLoadDevices = this.lazyLoadDevices || tab == "Devices";
      this.activeTab = tab;
    },
    formatPermissions(permissions) {
      let tokens = [];
      if (permissions == -1) {
        tokens.push("IT");
      }
      if ((permissions & 1 << 0) > 0) {
        tokens.push("USERS_READ");
      }
      if ((permissions & 1 << 1) > 0) {
        tokens.push("USERS_EXTENDED_READ");
      }
      if ((permissions & 1 << 2) > 0) {
        tokens.push("TICKETS_READ");
      }
      if ((permissions & 1 << 3) > 0) {
        tokens.push("APPLICATONS_READ");
      }
      if ((permissions & 1 << 4) > 0) {
        tokens.push("AUTOMATION_HEALTH");
      }
      if ((permissions & 1 << 6) > 0) {
        tokens.push("OKTA_STATUS_READ");
      }

      return tokens.join(", ");
    }
  },
  async beforeMount() {
    // load user summary on load
    let userId = this.$route.params.id || "me";
    this.user = await fetchFromApi(this, '/api/user/' + userId);
    if (this.user)
      document.title = `${this.user.id} - ${this.user.firstname} ${this.user.lastname}`;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section.highlight {
  padding-bottom: 1.5em;
}
.product-plus-user-icon {
  color: rgb(0, 157, 224);
  font-size: 1.5rem;
  margin-left: -5px;
  
}

</style>