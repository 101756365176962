<template>
  <div id="search-container" :class="{expanded: expanded}">
    <div id="searchBox">
      <div id="outerSearch" title="Search (Alt+s, cmd+s) for people, tickets, and more">
        <i class="fa fa-search" aria-hidden="true"></i> 
        <input id="orgchartsearch" autocomplete="off" name="orgchartsearch" type="text" autofocus accesskey="s"
          v-model="searchQuery" @click="onClick" @focus="onFocus" @blur="onBlur" @keydown="handleKeyDown($event)" @keyup="handleSearchKey($event)"
          placeholder="Search for people, tickets, and more" />
        <button v-show="searchQuery.length > 0" @click="clearQuery" title="Clear the query" class="queryReset subtle2">
          <svg width="20" height="20" viewBox="0 0 20 20"><path d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </button>
      </div>
    </div>
    <!-- expanded content -->
    <div class="transparent-overlay" @click="expanded = false" v-if="expanded"></div>
    <div class="outerResults" v-if="expanded">
      <!-- recent searched -->
      <div v-if="searchQuery.length == 0" class="searchResults" style="margin-top: -5px;">
        <header>
          Recent searches 
          <button class="subtle" title="Clear recent searches" @click="clearRecent()"><i class="fa-regular fa-trash-can"></i></button>
        </header>
        <div style="" @click="onSelect(entry)" :class="{'searchEntry': true, 'selected': entry.searchSelected, 'ctrlPressed': this.ctrlPressed}" v-for="entry in searchResult" :key="entry.id">
          <div class="icon" style="padding-top: 2px;height: inherit;font-size: 14px;"><i class="fa-solid fa-clock-rotate-left"></i></div>
          <div class="header">{{ entry.name }}</div>
        </div>
      </div>
      <!-- search results -->
      <div v-else-if="searchResult && searchResult.length > 0" class="searchResults">
        <div @click="onSelect(entry)" :class="{'searchEntry': true, 'selected': entry.searchSelected, 'ctrlPressed': this.ctrlPressed}" v-for="entry in searchResult" :key="entry.id">
          <div class="icon">
            <i v-if="entry.type === 'person'" class="fa-regular fa-user"></i>
            <i v-else-if="entry.type === 'app'" class="fa-solid fa-layer-group"></i>
            <i v-else-if="entry.type === 'ticket'" class="fa-solid fa-ticket"></i>
          </div>
          <div class="title">{{ entry.name }}</div>
          <div class="sub">{{entry.email}} | {{entry.id}}</div>        
        </div>
      </div>
      <div v-else-if="loadingData" class="searchResults">
        <header>Searching...</header>
      </div>
      <div v-else class="searchResults">
        <header>No results found</header>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchFromApi} from '@/services/AppTools.js'
import {permissions} from '@/services/Permissions.js'

export default {
  data() {
    return {
      searchResult: [],
      searchQuery: "",
      selectedResult: -1,
      ctrlPressed: false,
      loadingData: false,
      showReset: false,
      expanded: false,
    };
  },
  methods: {
    clearQuery() {
      this.searchQuery = "";
      this.searchPeople();
    },
    clearRecent() {
      window.sessionStorage.removeItem("recent_searches");
      this.searchResult = null;
    },
    onClick(event){
      this.searchPeople();
    },
    onFocus(event) {
      // if (event.target.autofocus) {
      //   event.target.autofocus = false;
      //   return;
      // }

      // this.searchPeople();
    },
    onBlur() {
    //  this.expanded = false;
      // need timeout as blur will fire before the click event on the search results
      // let self = this;
      // setTimeout(function() {
        
      // //   self.searchResult = null;
      // //   self.ctrlPressed = false;
      // //  this.expanded = false;
      // }, 200);
    },
    onSelect(entry) {
      console.log("onSelect")
      this.searchResult = null;
      let recentSearches = JSON.parse(window.sessionStorage.getItem("recent_searches")) || []
      recentSearches.unshift(entry); // add to front
      window.sessionStorage.setItem("recent_searches", JSON.stringify(recentSearches));

      // this.$emit('onPersonSelected', entry);
      if(entry.type == 'person') {
        window.location.href = '/user/' + entry.id;
        return
      } else if(entry.type == 'app') {
        window.location.href = '/application/' + entry.id;
        return
      } else if(entry.type == 'ticket') {
        window.location.href = '/ticket/' + entry.id;
        return
      }
      //this.$router.push({ name: 'AppView', params: { id: entry.id } })
    },
    handleKeyDown(event) {
      if(event.key === "Control"){
        this.ctrlPressed = true;
        this.$forceUpdate();  // Notice we have to use a $ here
      }
    },
    handleSearchKey(event) {
      if (event.code === "Tab") {
        return;
      }

      // console.log(event)
      this.ctrlPressed = this.ctrlPressed && event.key !== "Control";
      if (event.code === "ArrowDown") {
        if(this.searchResult && this.searchResult.length > 0){
          this.selectedResult = (this.selectedResult + 1) % this.searchResult.length

          this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
        }
        event.prevent = true;
          return
      }
      if (event.code === "ArrowUp") {
        if(this.searchResult && this.searchResult.length > 0){
          this.selectedResult = (this.selectedResult - 1 + this.searchResult.length) % this.searchResult.length

          this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
        }
          return
      }
      if (event.code === "Escape") {
        this.selectedResult = -1;
        this.searchResult = null;
        this.expanded = false;
        return
      }
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if(this.selectedResult >= 0) {
          let selected = this.searchResult[this.selectedResult];
          if(event.ctrlKey) {
            window.open(selected.profileUrl, '_blank');
            return
          }
          this.onSelect(selected)
        }
        return
      }

      if (event.key === "Control") {
        return
      }

      this.selectedResult = -1;
      this.searchPeople();
    },
    async searchPeople() {
      this.expanded = true;
      let query = this.searchQuery.toLowerCase().trim();

      // no search query, show recent searches
      if (query.length == 0) {
        let recentSearches = JSON.parse(window.sessionStorage.getItem("recent_searches")) || []
        this.searchResult = recentSearches.filter((item, index) => item != null);
        this.selectedResult = 0;
        this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
        return;
      }

      // return nothing if query is too short
      if(query.length < 3) {
        this.searchResult = []
        return
      }

      // search for people/tickets/etc...
      let allFound = null;
      
      if (query.includes("@")) { // filter by email
        query = query.trim();
        allFound = (await this.loadData(query)).filter(entry =>  query == entry.email.toLowerCase());
      }
      else if(!isNaN(query)) { // filter by employee id
        let id = query.trim()
        allFound = [] //(await this.loadData(query)).filter(entry =>  id == entry.id); TODO uncomment when including DD employees

        if (id >= 90000 && id < 1000000 && permissions.hasTicketsRead) {
          allFound.push({
            "type": "ticket",
            "name": "Ticket " + id,
            "id": id
          })
        } else {
          allFound = (await this.loadData(query)).filter(entry => id == entry.id);
        }
      }
      else { // filter by name
        let queryParts = query.toLowerCase().split(" ");

        allFound = (await this.loadData(query)).filter(entry => {
          let nameparts = entry.name.toLowerCase().split(" ");
          for(let part of queryParts){
            if(!nameparts.some(np => np.startsWith(part))) {
              return false;
            }
          }
          return true;
        });
      }

      this.searchResult = allFound.slice(0, 15);
      this.selectedResult = 0;
      this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
    },
    async loadData(query) {
      // load from cache / session storage
      let allpeople = window._searchData;
      if (!allpeople) {
        allpeople =  JSON.parse(window.sessionStorage.getItem("app_searchbox_data"))
      }

      // load from server
      if (!allpeople) {
        allpeople = await fetchFromApi(this, '/api/searchdata', null, {"filter": query}); // load search results from server as long the local cache is not ready
        loadFromApi(this); // start loading employee data from server to local cache. Do not wait for the result.
      }

      return allpeople;

      async function loadFromApi(self) {
        console.log("ds")
        if(self.loadingData || window._searchData) {
          return; // load data has alredy been triggered
        }

        self.loadingData = true
        allpeople = await fetchFromApi(self, '/api/searchdata', data => data);

        window._searchData = allpeople;
        window.sessionStorage.setItem("app_searchbox_data", JSON.stringify(allpeople));
        console.log("search data ready")
        self.loadingData = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

#search-container {
  flex-grow: 2;
  max-width: 17em;
  height: 100%;
}

#searchBox {
  display: flex;
  max-width: 17em;
  height: 42px;
  margin: 0 6px;

  #outerSearch {
    display: flex;
    border-radius: 5px;
    padding: 1ex;
    color: #716E7B;
    font-size: 1em;
    font-size: 20px;
    width: 100%;

    background-color:  var(--theme-accent);
    border: 1px solid  var(--theme-accent);
    color: var(--theme-text-color);
    filter: brightness(0.95);

    i {
      margin: 0 .5ex;
    }

    input, input:focus {
      border: 0;
      outline: none;
      min-width: 5em;
      background: none;
      font-size: 0.8em;
      flex-grow: 2;
      // color: black;
    }
    input, input::placeholder {

      color: var(--theme-text-color);
    }
    input::placeholder {
      font-family: 'Roboto';
      font-weight: 300;
      letter-spacing: 0.5px;
    }
    .queryReset svg {
      color: var(--theme-text-color);
    }
  }
}

#search-container.expanded {
  #searchBox {
    input, input:focus, input::placeholder, .queryReset svg {
      color: black;
    }
    #outerSearch {
      z-index: 415;
      color: black;
      background-color: white;
      border: 1px solid transparent;
    }
  }
  .transparent-overlay {
    z-index: 400;
  }

  .outerResults {
    // transition: all 2s ease;
    // animation: fadeIn 0.1s;
    position: relative;
    background: white;
    border-radius: 5px;

    // width: 20em;
    // max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, 
                rgba(0, 0, 0, 0.1) 0px 0px 0.25rem 0px, 
                rgba(0, 0, 0, 0.12) 0px 0.25rem 0.25rem 0px;
    z-index: 2000;
    color: black;
    // ----
    top: -49px;
    padding-top: 56px;
    z-index: 410; // overlay has z-index 400
    // width: 17.5em;
    // padding-left: 1ex;
    // padding-right: 1ex;
    border-radius: 12px;
    min-height: 3em;
  }
  .searchResults {
    padding-bottom: .5em;
    max-height: 15em;
    overflow-y: auto;

    header {
      font-weight: 500; 
      font-size: 15px;
      padding: 1ex 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .searchEntry {
    padding: 1ex 1em;
    font-size:16px;
    position: relative;
    cursor: pointer;
    padding-right: 4em;
    overflow-x: hidden;
    // text-overflow: ellipsis;
    // text-wrap: nowrap;

    .icon {
      /* border: 2px solid rgb(237, 239, 240);
      border-radius: 100px; */
      width: 25px;
      height: 40px;
      display: inline;
      float: left;
      margin-right: 1ex;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20pt;
    }

    // .title {
    // }

    .sub {
      margin-top: 1ex;
      color:#716E7B;
      font-size:13px;
    }
  }
}

.searchResults .selected, .searchEntry:hover {
  background-color: $grayLight;
}

button.subtle2 {
  background: none;
  color: $gray;
  stroke-width: 1.6;
  padding: 0px;

  :hover {
    color: $accentColor;
    background: none;
  }
}
</style>

<!-- Overlay is in another component and css must not be scoped -->
<style lang="scss">
#search-overlay {
  #search-container, #searchBox {
    max-width: 100%;

    #outerSearch, input, input::placeholder {
      background: none;
      color: black!important;
    }
  }

  .outerResults {
    background-color: transparent!important;
    box-shadow: none!important;

    .searchResults {
      max-height: none!important;
      overflow-y: visible!important;
    }
  }
}
</style>