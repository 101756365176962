<template>
  <div class="searchbox">
    <i class="fa fa-search"></i>
    <input type="text" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" accesskey="a"/>
    <button v-show="modelValue && modelValue.length > 0" @click="clearQuery" title="Clear the filter" class="query-reset">
      <svg style="scale: 0.8" width="20" height="20" viewBox="0 0 20 20"><path d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    </button>
  </div>
</template>

<script>
  export default {
    name: "SearchBox",
    props: ["placeholder", "modelValue"],
    emits: ["update:modelValue"],
    methods: {
      clearQuery() {
        this.$emit('update:modelValue', '');
      }
    }
  }
</script>

<style scoped lang="scss">
  .searchbox {
    align-items: center; 
    display: flex;
    width: 300px;
    position: relative;

    i {
      position: absolute;
      left: 14px;
      font-size: 14px;
      color: #666;
    }
    input {
      padding: 0.5ex 1em;
      border: 1px solid rgba(68, 68, 68, 0.15);
      border-radius: 30px;
      height: 2em;
      padding-left: 2.6em;
      padding-right: 2.4em;
      font-size: 14px;
      width: 100%;
      min-width: 40px;
    }
    input:placeholder-shown {
      font-style: italic;
    }
    .query-reset {
      position: absolute;
      right: -7px;
      background: none;
      color: black;
    }
  }
</style>
