<template>
  <div id="login">
   <div id="login-background">
      <div class="center-block">
        <div class="logo-container">
        </div>
        <button id="signin" type="submit" autofocus @click="signIn()">Sign in</button>
      </div>
   </div>
  </div>
</template>

<script>
  import {signIn as authSignIn, getSignedIn} from '@/services/SecurityService';

  export default {
    name: 'LoginPage',
    methods: {
      async signIn() {
        let ar = await this.tryAutoReturn();
        if (!ar) {
          const urlParams = new URLSearchParams(window.location.search);
          const ref = urlParams.get('ref');
          authSignIn(ref);
        }
      },
      async tryAutoReturn() {
        console.log('checking for active session');
        const signedIn = await getSignedIn();
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        if(signedIn) {
          console.log('already signed in');
          window.location.href = /^\/[a-zA-Z0-9\-._~%!$&'()*+,;=:@]/.test(ref) ? ref : '/'; // only allow relative paths
          return true;
        }
        return false;
      }
    },
    async beforeMount() {
      // auto return to ?ref if active session
      console.log('adding window focus listener');
      window.addEventListener("focus", this.tryAutoReturn);
    },
    async beforeUnmount() {
      window.removeEventListener("focus", this.tryAutoReturn);
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  #login {
    font-family: 'Roboto', sans-serif;
  }

  #login-background {
    transition: opacity 0.2s ease-out;
    pointer-events: none;

    background: url("../../public/static/login-background.png");    
    background-position: center;
    background-size:cover;


    position: fixed;
    
    z-index: 1000;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    pointer-events: all;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #login .logo-container {
    width: 80vw;
    height: 200px;

    background: url("../../public/static/dd_wolt_logo_white.png");
    background-position: center bottom;
    background-position-y: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #login .center-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 7em;
  }

  #login .app-title {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: .75em;
    color: #fff;
    margin: 24px auto;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
  }
   
  #login #signin {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;

    font-family: omnes-pro,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(4vw, 28px);
    text-transform: uppercase;
    letter-spacing: .4ex;

    color: white;
    border: 0.3ex solid white;
    border-radius: 1.5ex;
    margin-top: 3em;
    padding: 1em 1.5em;

    background: rgba(0, 0, 0, 0.0);
    background-size: 200% 100%;
    background-position:left bottom;

    display: block;
    transition:background-position 0s;
  }

  #login #signin:hover {
    color: #92E0F3;
    background-color: white;
  }

  #login #signin:focus-visible {
    outline: 2px solid #53C6E8;
  }

</style>
